export const postMessageToParent = (event: string, message?: string): void => {
  window.parent.postMessage(
    {
      source: "zappa-protect",
      payload: {
        event: event,
        ...(message && { payload: message }),
      },
    },
    "*"
  );
};