import React from "react";
import { IChallengeInputs } from "../../../interfaces";
import "./Puzzle.css";
import cookie from "cookiejs";

const APIBaseURL = process.env.REACT_APP_API_BASE_URL;

type PuzzleProps = IChallengeInputs & {
  reattempt?: boolean;
  challenge_key?: string;
  canvasSize?: number;
  drawingOrigin?: number;
  containerSize?: number;
};

class PuzzleChallenge {
  constructor(
    startX: number,
    startY: number,
    endX: number,
    endY: number,
    width: number,
    height: number,
    dragImagePath: string,
    retries: number,
    backgroundImagePath: string,
    spriteHighlightColor: string
  ) {
    this.startX = startX;
    this.startY = startY;
    this.endX = endX;
    this.endY = endY;
    this.width = width;
    this.height = height;
    this.dragImagePath = dragImagePath;
    this.retries = retries;
    this.backgroundImagePath = backgroundImagePath;
    this.spriteHighlightColor = spriteHighlightColor;
  }
  startX: number;
  startY: number;
  endX: number;
  endY: number;
  width: number;
  height: number;
  retries: number;
  dragImagePath: string;
  backgroundImagePath: string;
  spriteHighlightColor: string;
}

const Puzzle: React.FC<PuzzleProps> = ({
  reattempt = false,
  canvasSize = 200,
  drawingOrigin = 0,
  containerSize = 300,
  challenge_key,
  setResult,
}) => {
  const canvasRef = React.useRef<HTMLCanvasElement | null>(null);
  const canvasCtxRef = React.useRef<CanvasRenderingContext2D | null>(null);
  let Puzzle: PuzzleChallenge;

  const randomIndex = (arr: any[]) => {
    //used to select a random puzzle from the array
    return Math.floor(Math.random() * arr.length);
  };

  interface assets {
    background: string;
    sprite: string;
    section: {
      top: number;
      left: number;
      width: number;
      height: number;
    };
  }
  interface PuzzleData {
    key: string;
    siteId: string;
    spriteHighlightColor: string;
    minimumRiskScore: number;
    primaryDesktopPuzzleData: assets[];
    secondaryDesktopPuzzleData: assets[];
    primaryMobilePuzzleData: assets[];
    secondaryMobilePuzzleData: assets[];
  }

  const fetchPuzzle = () => {
    fetch(`${APIBaseURL}/api/puzzles/challenge`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        Key: challenge_key,
      }),
    })
      .then((res) => res.json())
      .then((puzzles: PuzzleData) => {
        //TODO select based in desktop or mobile rendering the image 0,1 or the 2,3 index
        const platformPuzzles: assets[] =
          containerSize === 400
            ? [
                puzzles.primaryDesktopPuzzleData[
                  randomIndex(puzzles.primaryDesktopPuzzleData)
                ],
                puzzles.secondaryDesktopPuzzleData[
                  randomIndex(puzzles.secondaryDesktopPuzzleData)
                ],
              ]
            : [
                puzzles.primaryMobilePuzzleData[
                  randomIndex(puzzles.primaryMobilePuzzleData)
                ],
                puzzles.secondaryMobilePuzzleData[
                  randomIndex(puzzles.secondaryMobilePuzzleData)
                ],
              ];
        const p = platformPuzzles[!reattempt ? 0 : 1];
        Puzzle = new PuzzleChallenge(
          2,
          2,
          p.section.left + p.section.width,
          p.section.top + p.section.height,
          p.section.width,
          p.section.height,
          p.sprite,
          3,
          p.background,
          puzzles.spriteHighlightColor
        );
        setCanvas();
      })
      .catch((err) => {
        console.error(err);
      });
  };

  React.useEffect(() => {
    fetchPuzzle();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setCanvas = async () => {
    let retry = 1;
    const canvas = canvasRef.current as HTMLCanvasElement;
    if (canvas) {
      canvas.width = canvasSize;
      canvas.height = canvasSize;
      canvasCtxRef.current = canvas.getContext(
        "2d"
      ) as CanvasRenderingContext2D;
      let ctx = canvasCtxRef.current;
      const scaleX = containerSize / canvasSize; // Scale ratio to consider because we are rendering 200px square canvas is 300px container
      const scaleY = containerSize / canvasSize;
      let newPuzzle: PuzzleChallenge;
      newPuzzle = Puzzle;

      const img = document.createElement("img");
      img.src = newPuzzle.backgroundImagePath;

      let dragStart = { x: 0, y: 0 };
      let drag = false;

      img.addEventListener("load", () => {
        ctx?.drawImage(
          img,
          drawingOrigin,
          drawingOrigin,
          canvasSize,
          canvasSize
        );
        const img1 = document.createElement("img");
        const shrinkX = canvas.width / img.width;
        const shrinkY = canvas.height / img.height;
        const sprite = {
          image: img1,
          width: Math.ceil(newPuzzle.width * shrinkX),
          height: Math.ceil(newPuzzle.height * shrinkY),
        };
        if (Math.ceil(img.height / 2) >= newPuzzle.endY - newPuzzle.height) {
          //Blank section is on the top half of the puzzle so we will force the sprite starting point to the oposite direction
          newPuzzle.startY = canvasSize - 2 - sprite.height; //Max height is 200 but we need to take into consideration the 2 pixels of the border
        } else {
          //Blank section is on the bottom half of the puzzle so we will force the sprite starting point to the oposite direction
          newPuzzle.startY = 2;
        }

        if (Math.ceil(img.width / 2) >= newPuzzle.endX - newPuzzle.width) {
          //Blank section is on the top half of the puzzle so we will force the sprite starting point to the oposite direction
          newPuzzle.startX = canvasSize - 2 - sprite.width; //Max width is 200 but we need to take into consideration the 2 pixels of the border
        } else {
          //Blank section is on the bottom half of the puzzle so we will force the sprite starting point to the oposite direction
          newPuzzle.startX = 2;
        }
        img1.src = newPuzzle.dragImagePath;

        img1.addEventListener("load", () => {
          ctx.fillStyle = newPuzzle.spriteHighlightColor;
          ctx?.beginPath();
          ctx?.rect(
            newPuzzle.startX - 2,
            newPuzzle.startY - 2,
            sprite.width + 4,
            sprite.height + 4
          );
          ctx?.fill();
          ctx?.drawImage(
            sprite.image,
            newPuzzle.startX,
            newPuzzle.startY,
            sprite.width,
            sprite.height
          );
          canvas.addEventListener(
            "mousedown",
            (event) => {
              const rect = canvas.getBoundingClientRect();
              const mouseX = Math.round(
                ((event.clientX - rect.left) / rect.width) * canvasSize
              );
              const mouseY = Math.round(
                ((event.clientY - rect.top) / rect.height) * canvasSize
              );
              const spriteRight = newPuzzle.startX + sprite.width;
              const spriteBottom = newPuzzle.startY + sprite.height;
              if (
                mouseX >= newPuzzle.startX &&
                mouseX <= spriteRight &&
                mouseY >= newPuzzle.startY &&
                mouseY <= spriteBottom
              ) {
                dragStart = {
                  x: mouseX - newPuzzle.startX,
                  y: mouseY - newPuzzle.startY,
                };
                drag = true;
              }
            },
            false
          );

          canvas.addEventListener(
            "mouseup",
            (event) => {
              if (drag) {
                drag = false;
                const rect = canvas.getBoundingClientRect();
                const mouseX = Math.round(
                  ((event.clientX - rect.left) / rect.width) * canvasSize
                );
                const mouseY = Math.round(
                  ((event.clientY - rect.top) / rect.height) * canvasSize
                );
                const minX = Math.round(
                  (newPuzzle.endX - newPuzzle.width) * shrinkX
                );
                const minY = Math.round(
                  (newPuzzle.endY - newPuzzle.height) * shrinkY
                );

                // gets the top left point of the sprite using the drag start point
                const spriteOriginOnDrop = {
                  x: mouseX - dragStart.x,
                  y: mouseY - dragStart.y,
                };

                //create a square to average out the margin of error
                const successBuffer = Math.ceil(
                  Math.sqrt(sprite.width * sprite.height) * 0.05
                );
                // the zone that the sprite origin point can enter to succeed, is a square to account for longer rectangles
                const spriteBufferZone = {
                  minX: minX - successBuffer,
                  maxX: minX + successBuffer,
                  minY: minY - successBuffer,
                  maxY: minY + successBuffer,
                };

                if (
                  spriteOriginOnDrop.x >= spriteBufferZone.minX &&
                  spriteOriginOnDrop.x <= spriteBufferZone.maxX &&
                  spriteOriginOnDrop.y >= spriteBufferZone.minY &&
                  spriteOriginOnDrop.y <= spriteBufferZone.maxY
                ) {
                  ctx?.drawImage(
                    img,
                    drawingOrigin,
                    drawingOrigin,
                    canvasSize,
                    canvasSize
                  );
                  ctx?.beginPath();
                  ctx?.rect(
                    mouseX - dragStart.x - 2,
                    mouseY - dragStart.y - 2,
                    sprite.width + 4,
                    sprite.height + 4
                  );
                  ctx?.fill();
                  ctx?.drawImage(
                    img1,
                    mouseX - dragStart.x,
                    mouseY - dragStart.y,
                    sprite.width,
                    sprite.height
                  );
                  setResult("success", !reattempt ? 1 : 2);
                } else {
                  if (retry >= newPuzzle.retries) {
                    cookie.set(
                      `challenge_${!reattempt ? 1 : 2}_fail`,
                      new Date().toISOString(),
                      { expires: 1 }
                    );
                    setResult("failure", !reattempt ? 1 : 2);
                  }
                  retry++;
                  ctx?.drawImage(
                    img,
                    drawingOrigin,
                    drawingOrigin,
                    canvasSize,
                    canvasSize
                  );
                  ctx?.beginPath();
                  ctx?.rect(
                    newPuzzle.startX - 2,
                    newPuzzle.startY - 2,
                    sprite.width + 4,
                    sprite.height + 4
                  );
                  ctx?.fill();
                  ctx?.drawImage(
                    sprite.image,
                    newPuzzle.startX,
                    newPuzzle.startY,
                    sprite.width,
                    sprite.height
                  );
                }
              }
            },
            false
          );

          canvas.addEventListener(
            "mousemove",
            (event) => {
              if (drag) {
                const rect = canvas.getBoundingClientRect();
                const mouseX = Math.round(
                  ((event.clientX - rect.left) / rect.width) * canvasSize
                );
                const mouseY = Math.round(
                  ((event.clientY - rect.top) / rect.height) * canvasSize
                );
                const newX = mouseX - dragStart.x;
                const newY = mouseY - dragStart.y;
                ctx?.drawImage(
                  img,
                  drawingOrigin,
                  drawingOrigin,
                  canvasSize,
                  canvasSize
                );
                ctx?.beginPath();
                ctx?.rect(
                  newX - 2,
                  newY - 2,
                  sprite.width + 4,
                  sprite.height + 4
                );
                ctx?.fill();
                ctx?.drawImage(
                  sprite.image,
                  newX,
                  newY,
                  sprite.width,
                  sprite.height
                );

                canvas.onmousemove = null;
              }
            },
            false
          );

          canvas.addEventListener(
            "touchstart",
            (event) => {
              const touch = event.touches[0];
              const rect = canvas.getBoundingClientRect();
              const touchX = Math.round(
                ((touch.clientX - rect.left) / rect.width) * canvasSize
              );
              const touchY = Math.round(
                ((touch.clientY - rect.top) / rect.height) * canvasSize
              );
              // Check if touch is within the sprite boundaries using scaled dimensions
              const spriteRight = newPuzzle.startX + sprite.width;
              const spriteBottom = newPuzzle.startY + sprite.height;
              if (
                touchX >= newPuzzle.startX &&
                touchX <= spriteRight &&
                touchY >= newPuzzle.startY &&
                touchY <= spriteBottom
              ) {
                dragStart = {
                  x: touchX - newPuzzle.startX,
                  y: touchY - newPuzzle.startY,
                };
                drag = true;
              }
            },
            false
          );

          canvas.addEventListener(
            "touchend",
            (event) => {
              if (drag) {
                drag = false;
                const touch = event.changedTouches[0];
                const rect = canvas.getBoundingClientRect();
                const touchX = Math.round(
                  ((touch.clientX - rect.left) / rect.width) * canvasSize
                );
                const touchY = Math.round(
                  ((touch.clientY - rect.top) / rect.height) * canvasSize
                );
                if (
                  touchX >=
                    Math.round((newPuzzle.endX - newPuzzle.width) * shrinkX) &&
                  touchX <= Math.round(newPuzzle.endX * shrinkX) &&
                  touchY >=
                    Math.round((newPuzzle.endY - newPuzzle.height) * shrinkY) &&
                  touchY <= Math.round(newPuzzle.endY * shrinkY)
                ) {
                  ctx?.drawImage(
                    img,
                    drawingOrigin,
                    drawingOrigin,
                    canvasSize,
                    canvasSize
                  );
                  ctx?.beginPath();
                  ctx?.rect(
                    touchX - dragStart.x - 2,
                    touchY - dragStart.y - 2,
                    sprite.width + 4,
                    sprite.height + 4
                  );
                  ctx?.fill();
                  ctx?.drawImage(
                    img1,
                    touchX - dragStart.x,
                    touchY - dragStart.y,
                    sprite.width,
                    sprite.height
                  );
                  setResult("success", !reattempt ? 1 : 2);
                } else {
                  if (retry >= newPuzzle.retries) {
                    cookie.set(
                      `challenge_${!reattempt ? 1 : 2}_fail`,
                      new Date().toISOString(),
                      { expires: 1 }
                    );
                    setResult("failure", !reattempt ? 1 : 2);
                  }
                  retry++;
                  ctx?.drawImage(
                    img,
                    drawingOrigin,
                    drawingOrigin,
                    canvasSize,
                    canvasSize
                  );
                  ctx?.beginPath();
                  ctx?.rect(
                    newPuzzle.startX - 2,
                    newPuzzle.startY - 2,
                    sprite.width + 4,
                    sprite.height + 4
                  );
                  ctx?.fill();
                  ctx?.drawImage(
                    sprite.image,
                    newPuzzle.startX,
                    newPuzzle.startY,
                    sprite.width,
                    sprite.height
                  );
                }
              }
            },
            false
          );

          canvas.addEventListener(
            "touchmove",
            (event) => {
              if (drag) {
                const touch = event.touches[0];
                const rect = canvas.getBoundingClientRect();
                const touchX = Math.round(
                  ((touch.clientX - rect.left) / rect.width) * canvasSize
                );
                const touchY = Math.round(
                  ((touch.clientY - rect.top) / rect.height) * canvasSize
                );
                const newX = touchX - dragStart.x;
                const newY = touchY - dragStart.y;
                ctx?.drawImage(
                  img,
                  drawingOrigin,
                  drawingOrigin,
                  canvasSize,
                  canvasSize
                );
                ctx?.beginPath();
                ctx?.rect(
                  newX - 2,
                  newY - 2,
                  sprite.width + 4,
                  sprite.height + 4
                );
                ctx?.fill();
                ctx?.drawImage(
                  sprite.image,
                  newX,
                  newY,
                  sprite.width,
                  sprite.height
                );
                event.preventDefault();
              }
            },
            false
          );
        });
      });
    }
  };

  return (
    <div
      data-testid="puzzle-container"
      className={
        containerSize === 400
          ? "zp-pzz-desktop-container"
          : "zp-pzz-mobile-container"
      }
    >
      <canvas
        data-testid="puzzle-canvas"
        className="zp-pzz-canvas"
        ref={canvasRef}
      />
    </div>
  );
};

export default Puzzle;
