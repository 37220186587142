import React, { Suspense, useEffect, useRef } from "react";
import { Container } from "../Container/Container";
import LoadingComponent from "../LoadingComponent/LoadingComponent";
import Instructions from "../Instructions/Instructions";
import "./Challenge.css";
import {
  Client,
  IAdblockDetect,
  IDevicePrint,
  IReportData,
} from "@zappahq/core";
import { postMessageToParent } from "../../utilities/postMessage";
import cookie from "cookiejs";
import Puzzle from "../challenges/Puzzle/Puzzle";

type IDateTags = {
  captcha_init: Date | null;
  captcha_key_verified: Date | null;
  captcha_render: Date | null;
  captcha_result_processing: Date | null;
  captcha_result_completed: Date | null;
  captcha_result_presented: Date | null;
};

const APIBaseURL = process.env.REACT_APP_API_BASE_URL;

type IChallenge = {
  reattempt?: boolean;
};

type IInstructions = { [key: string]: string };
type AnalyzeFn = (data: any) => Promise<Response>;

const defaultInstructions: IInstructions = {
  puzzle: "Drag the highlighted image into the white placeholder section",
};

function Challenge({ reattempt = false }: IChallenge) {
  const params = new URLSearchParams(window.location.search);
  const key = params.get("key")?.toString() ?? "";
  const ip = params.get("publicIp")?.toString() ?? "";
  const record = params.get("record")?.toString() ?? "";
  const isMobile = params.get("mobile")?.toString() === "true";
  const [zpClient, setZpClient] = React.useState<Client>();

  const adblockDetected = useRef<IAdblockDetect | null>(null); //useRef used because useState does not update value till next render.
  const devicePrint = useRef<IDevicePrint | null>(null);
  const fetched = useRef(false);
  const [dateTags, setDateTags] = React.useState<IDateTags>({
    captcha_init: new Date(),
    captcha_key_verified: null,
    captcha_render: null,
    captcha_result_processing: null,
    captcha_result_completed: null,
    captcha_result_presented: null,
  });
  const beforeUnloadHandler = React.useRef<(data: Event) => string | null>(
    () => null
  );

  let challengeTags: any = {}; //need to use global because useState does not update on time for submitData() to use it.

  const postSuccess = (data: any) => {
    postMessageToParent("challenge-record", `${data}`);
    postMessageToParent("challenge-result", "success");
  };

  const postFailure = (attempt: number) => {
    if (attempt === 1) postMessageToParent("challenge-result", "failure");
    else postMessageToParent("challenge-reattempt", "failure");
  };

  const siteVerify = ({
    id,
    challenge_key,
  }: {
    id: string;
    challenge_key: string;
  }) => {
    return new Promise<Response>((resolve, reject) => {
      fetch(`${APIBaseURL}/api/zen/siteverify`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          id: id,
          challenge_key: challenge_key,
        }),
      })
        .then((res) => resolve(res))
        .catch((err) => reject(err));
    });
  };

  const analyze = (data: any) => {
    return new Promise<Response>((resolve, reject) => {
      fetch(`${APIBaseURL}/analyze`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      })
        .then((res) => resolve(res))
        .catch((err) => reject(err));
    });
  };

  const beaconAnalyze = (data: any) => {
    navigator.sendBeacon(`${APIBaseURL}/analyze`, JSON.stringify(data));
    return Promise.resolve(new Response());
  };

  const submitData = (analyzeFn: AnalyzeFn = analyze) => {
    unbindAbandonEvent();
    return new Promise<string>((resolve, reject) => {
      const fail_1 = cookie.get("challenge_1_fail") as string;
      const fail_2 = cookie.get("challenge_2_fail") as string;
      const form_data = cookie.get("formInputData") as string;
      cookie.remove("challenge_1_fail");
      cookie.remove("challenge_2_fail");
      challengeTags = {
        ...challengeTags,
        captcha_challenge_1_fail: fail_1 ? fail_1 : null,
        captcha_challenge_2_fail: fail_2 ? fail_2 : null,
      };
      let Zendata = {
        id: record,
        challenge_key: key,
        device_print: devicePrint.current,
        adblock: adblockDetected.current?.result,
        mousey: getTrackingData(),
        form_data,
        ip: ip,
        ...dateTags,
        ...challengeTags,
      };
      analyzeFn(Zendata)
        .then((res) => {
          if (res.ok)
            res.json().then((data) => {
              siteVerify({
                id: Zendata.id,
                challenge_key: Zendata.challenge_key,
              })
                .then((verify) => {
                  if (verify) resolve(data.id);
                  else reject(data.id);
                })
                .catch(() => reject(data.id));
            });
          else reject(Zendata.id);
        })
        .catch(() => reject(Zendata.id));
    });
  };

  const setChallengeResult = (result: "success" | "failure", puzzle: 1 | 2) => {
    if (result === "failure") {
      postFailure(puzzle);
    } else {
      challengeTags = {
        ...challengeTags,
        [`captcha_challenge_${puzzle}_complete`]: new Date(),
      };
      submitData()
        .then((id) => {
          postSuccess(id);
        })
        .catch(() => {
          postFailure(puzzle);
        });
    }
  };

  React.useEffect(() => {
    setZpClient(new Client(key));
  }, []);

  React.useEffect(() => {
    if (zpClient) {
      startdevicePrint().then((data) => (devicePrint.current = data));
      startTracking();
      zpClient
        .GetDevicePrint()
        .then((data: IDevicePrint) => {
          devicePrint.current = data;
        })
        .then(() => {
          zpClient.GetAdblockDetect().then((adData: IAdblockDetect) => {
            adblockDetected.current = adData;
          });
        })
        .catch((err: any) => {
          console.error(err);
        });
    }

    return () => {
      stopTracking();
    };
  }, [zpClient]);

  const keyCheck = ({ key, domain }: { key: string; domain: string }) => {
    return new Promise((resolve, reject) => {
      fetch(`${APIBaseURL}/api/sites/domainkeycheck`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          Key: key,
          Domain: domain,
        }),
      })
        .then((res) => res.json())
        .then((res) => {
          resolve(res.success);
        })
        .catch((err) => reject(err));
    });
  };

  useEffect(() => {
    if (!fetched.current) {
      keyCheck({ key, domain: window.document.referrer })
        .then((res) => {
          if (res) {
            if (!dateTags.captcha_key_verified)
              setDateTags({ ...dateTags, captcha_key_verified: new Date() });
            fetched.current = true;
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, [dateTags, key]);

  const startTracking = (): void => {
    if (!zpClient) {
      return;
    }
    zpClient.StartTracking({});
  };

  const stopTracking = (): void => {
    if (!zpClient) {
      return;
    }
    zpClient.StopTracking();
  };

  const getTrackingData = (): IReportData | null => {
    if (!zpClient) {
      return null;
    }
    return zpClient.GetTrackingData();
  };

  const startdevicePrint = (): Promise<IDevicePrint | null> => {
    if (!zpClient) {
      return Promise.resolve(null);
    }
    return new Promise((resolve) => {
      zpClient?.GetDevicePrint().then((data) => resolve(data));
    });
  };

  const bindAbandonEvent = () => {
    unbindAbandonEvent();
    beforeUnloadHandler.current = (event: Event) => {
      event.preventDefault();
      submitData(beaconAnalyze);
      return null;
    };
    window.addEventListener("unload", beforeUnloadHandler.current);
  };

  const unbindAbandonEvent = () => {
    window.removeEventListener("unload", beforeUnloadHandler.current);
  };

  const renderChallenge = () => {
    if (fetched.current) {
      bindAbandonEvent();
      challengeTags = { ...challengeTags, captcha_render: new Date() };
      return (
        <div className="flex items-center justify-center w-full h-full gap-2">
          <Container view="instructions" mobile={isMobile}>
            <Instructions
              instructions={defaultInstructions["puzzle"]}
              reattempt={reattempt}
            />
          </Container>
          <Puzzle
            setResult={setChallengeResult}
            challenge_key={key}
            reattempt={reattempt}
            canvasSize={isMobile ? 300 : 400}
            containerSize={isMobile ? 300 : 400}
          />
        </div>
      );
    } else return <LoadingComponent status="loading" />;
  };

  return (
    <Container view="challenge" className='bg'>
      <div
        id="zp-challenge-container"
        className="flex items-center justify-center w-full h-full bg"
      >
        <Suspense fallback={<LoadingComponent status="loading" />}>
          {renderChallenge()}
        </Suspense>
      </div>
    </Container>
  );
}

export { Challenge };
