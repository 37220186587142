import "./Head.css";
import { postMessageToParent } from "../../utilities/postMessage";

const Head = () => {
  const handleClick = () => {
    postMessageToParent("abort-challenge", "success");
  }

  return (
    <div className="Head" data-testid="head-container">
      <div>Instructions:</div>
      <button
        data-testid="close-button"
        onClick={handleClick}
        type="button">
        X
      </button>
    </div>
  );
}

export default Head;
