import * as React from "react";
import "./Container.css";

export interface ContainerProps extends React.HTMLAttributes<HTMLDivElement> {
  bgcolor?: string;
  children?: React.ReactNode;
  view: "start" | "help" | "challenge" | "instructions";
  mobile?: boolean;
}

export const Container: React.FC<ContainerProps> = ({
  children,
  view,
  mobile = false,
  ...rest
}) => {
  const mobileClass = mobile ? "--mobile" : "";
  return (
    <div
      {...rest}
      role="main"
      className={`zp-container zp-container-dimensions-width-${view}${mobileClass} zp-container-dimensions-height-${view}${mobileClass}`}
    >
      {children}
    </div>
  );
};
